<template lang="pug">
.FcwFooterColumn(v-if="items?.length")
  .FcwFooterColumn__item(
    v-for="(columnItem, index) in items"
    :key="columnItem.type + index"
  )
    FcwFooterLinks(
      v-if="columnItem.type === 'showcase-footer.footer-links'"
      v-bind="columnItem.props"
    )
    FcwFooterContact(
      v-if="columnItem.type === 'showcase-footer.contact'"
      v-bind="columnItem.props"
    )
    FcwFooterContact(
      v-if="columnItem.type === 'showcase-footer.general-contact'"
      v-bind="{ ...columnItem.props, ...generalContactInformation }"
    )
    FcwFooterSocialNetworks(
      v-if="columnItem.type === 'showcase-footer.social-networks'"
      v-bind="{ ...columnItem.props, items: socialNetworksItems }"
    )
    FcwFooterNews(
      v-if="columnItem.type === 'showcase-footer.news' || columnItem.type === 'showcase-footer.latest-news'"
      v-bind="columnItem.props"
    )
    FcwButtonLink(
      v-if="columnItem.type === 'showcase-footer.cta-button'"
      v-bind="columnItem.props"
    ) {{ columnItem.props.text }}
</template>

<style lang="stylus">
.FcwFooterColumn
  display flex
  flex-direction column
  gap rem(40)

  +media-down('md')
    &:nth-of-type(3)
      flex-wrap wrap
      flex-direction row

  +media-down('sm')
    &:nth-of-type(3)
      align-items center
      flex-direction column

.FcwFooterColumn__item
  width rem(280)
  min-width rem(220)
  max-width rem(280)
</style>

<script setup lang="ts">
import FcwFooterNews from '@/components/organisms/showcase/footer/FcwFooterNews.vue';
import FcwFooterLinks from '@/components/organisms/showcase/footer/FcwFooterLinks.vue';
import FcwFooterContact from '@/components/organisms/showcase/footer/FcwFooterContact.vue';
import FcwFooterSocialNetworks from '@/components/organisms/showcase/footer/FcwFooterSocialNetworks.vue';
import FcwButtonLink from '@/components/molecules/FcwButtonLink.vue';

import type { ShowcaseFooterColumnItem } from '@/components/organisms/FcwFooter.vue';
import type { SocialNetwork } from '@/components/organisms/showcase/footer/FcwFooterSocialNetworks.vue';

export interface FcwFooterColumnProps {
  /**
   * Footer column filled with components
   */
  items?: ShowcaseFooterColumnItem[];
}

const clientStore = useClientStore();
const { clientState } = storeToRefs(clientStore);

const generalContactInformation = computed(() => ({
  email: clientState.value.email ?? '',
  phoneNumber: clientState.value.phoneNumber ?? '',
  openingHours: clientState.value.openingHours ?? '',
}));

const socialNetworksItems = computed<SocialNetwork[]>(
  () => clientState.value.socialNetworks
);

withDefaults(defineProps<FcwFooterColumnProps>(), {
  items: () => [],
});
</script>
