import { createFlowStep } from '@/lib/helpers/flow';
import { CwAppErrorCode, ServiceType } from '@/models/enums';
import { createCwError } from '@/lib/helpers/errors';

/**
 * Front-end side check of the payment capacity of the user:
 * 1. Check if a payment method is provided
 * 2. Check if the wallet balance is greater or equal that the subscription price
 */
export const checkPaymentCapacity = createFlowStep<BenefitFlowPayload>(
  'checkPaymentCapacity',
  async payload => {
    const userHasPaymentCapacity = Boolean(payload.elements?.paymentMethodId);

    if (!userHasPaymentCapacity) {
      throw createCwError(CwAppErrorCode.OfferSubscriptionMissingPaymentMethod);
    }

    return payload;
  }
);

export const confirmBenefitPayment = createFlowStep<BenefitFlowPayload>(
  'confirmBenefitPayment',
  async payload => {
    const { secret, is3DsRequired } = payload.userOffer.payment;

    // Payment can't be confirmed without a payment method type
    if (!is3DsRequired || !payload.userOffer?.paymentMethodType) return payload;

    const { confirmPayment } = usePayment();

    const { error } = await confirmPayment({
      secret,
      paymentId: payload.userOffer.paymentId,
      paymentMethodType: payload.userOffer?.paymentMethodType,
    });

    if (error) {
      throw createCwError(error.code);
    }

    return payload;
  }
);

export const sendSubscriptionProofsToSupport =
  createFlowStep<BenefitFlowPayload>(
    'sendSubscriptionProofsToSupport',
    async payload => {
      const api = useApi();

      const isLeasingOffer = payload.userOffer?.isLeasing;
      const subscriptionProofs = payload.userOffer?.subscriptionProofs ?? [];
      const proofFileTokens = payload.elements?.subscriptionProofsTokens ?? [];

      if (subscriptionProofs.length === 0) {
        return payload;
      }
      // Basic check to verify there is at least as much proof file tokens than required subscription proofs
      if (proofFileTokens.length < subscriptionProofs.length) {
        throw createCwError(
          CwAppErrorCode.OfferSubscriptionMissingRequiredDocument
        );
      }

      const { error: subscriptionProofsSendError } = await api.post(
        '/client/support/subscription-proofs',
        {
          body: {
            attachmentTokens: proofFileTokens,
            customFields: {
              OfferId: payload.userOffer.id,
            },
            serviceType: isLeasingOffer
              ? ServiceType.Leasing
              : ServiceType.Sharing,
          },
        }
      );

      if (subscriptionProofsSendError.value) {
        throw createCwError(subscriptionProofsSendError.value.errorCode);
      }

      return payload;
    }
  );
