<!-- eslint-disable vue/multi-word-component-names -->
<template lang="pug">
.error
  FcwNavbar(
    :links="navLinks"
    plain
  )
    template(#actions="{ isPlain }")
      FcwNavbarCta(:inverted="isPlain")

  FcwContentTemplate(
    :title="String(error?.statusCode)"
    :content="errorMessage"
  )
    .error__actions
      FcwButtonLink(
        v-if="!isHomepage"
        :location="homepage"
      ) {{ t('common__go_home') }}
      FcwLocaleSelect.error__localeSelect(
        v-show="isHomepage"
        display-mode="name"
      )
  FcwFooter(v-bind="showcaseFooterProps")
</template>

<style lang="stylus">
.error
  .FcwContentTemplate
    overflow initial
    min-height 85vh

.error__actions
  margin rem(40) 0

.error__localeSelect
  width fit-content
</style>

<script setup lang="ts">
import type { NuxtError } from '#app';

interface ErrorProps {
  /**
   * Nuxt Error object
   */
  error: NuxtError;
}

const props = defineProps<ErrorProps>();

useClientTheme();
const { t } = useClientI18n();

const { homepage } = storeToRefs(useClientStore());
const route = useRoute();
const isHomepage = computed(() => route.name === homepage.value.name);

const errorMessage = computed(() => {
  const errorClass: `${number}xx` = `${Math.floor(
    props.error.statusCode / 100
  )}xx`;

  switch (errorClass) {
    case '4xx':
    default: {
      if (isHomepage.value) {
        return t('error__homepage_not_found');
      }
      return t('error__page_not_found');
    }
    case '5xx':
      return t('error__server_issue');
  }
});

useClientTitle(errorMessage);

const navLinks = await useClientNavbar();
const { data: showcaseFooterProps, error: footerError } = await useCmsFooter();
useCmsError(footerError);
</script>
