import { useHead } from '@unhead/vue';
import { keysOf } from '@fifteen/shared-lib';

import type { AxeptioCookiesChoices } from '@/types/axeptio';

const disabledTrackRoutes = ['embed'];
const disabledTrackRoutesRegex = new RegExp(
  `^(${disabledTrackRoutes.join('|')})`
);

/**
 * Handle user tracking consent + scripts injection
 */
export function useCookieConsent(): {
  choices: Ref<AxeptioCookiesChoices>;
  hasMadeChoice: Ref<boolean>;
} {
  const choices = useState<AxeptioCookiesChoices>(
    'useCookieConsent.choices',
    () => ({})
  );
  const isInit = useState<boolean>('useCookieConsent.isInit', () => false);
  const hasMadeChoice = useState<boolean>(
    'useCookieConsent.hasMadeChoice',
    () => false
  );
  const isTrackingAvailable = useState<boolean>(
    'useCookieConsent.isTrackingAvailable',
    () => true
  );

  const { locale } = useI18n();

  const config = useRuntimeConfig();

  const route = useRoute();

  watchImmediate(
    () => route.name,
    () => {
      const isRouteTrackingDisabled = disabledTrackRoutesRegex.test(
        route.name as string
      );

      if (isRouteTrackingDisabled) {
        isTrackingAvailable.value = false;
        return;
      }

      if (!isInit.value && process.client) {
        isInit.value = true;
        window.axeptioSettings = {
          clientId: config.public.userTrackingAxeptioClientId,
          cookiesVersion: `${config.public.userTrackingAxeptioCookiesVersionPrefix}-${locale.value}`,
        };

        (window._axcb = window._axcb || []).push(axeptio => {
          axeptio.on(
            'cookies:complete',
            _choices => (choices.value = _choices)
          );
        });

        useHead({
          script: [
            {
              async: true,
              defer: true,
              src: `//static.axept.io/sdk-slim.js`,
            },
          ],
        });

        watchOnce(choices, newChoices => {
          if (Object.keys(newChoices).length > 0) {
            hasMadeChoice.value = true;
          }
        });
      }
    }
  );

  watch(locale, newLocale => {
    if (!isInit.value || !isTrackingAvailable.value || !process.client) return;

    window.axeptioSDK?.setCookiesVersion(
      `${config.public.userTrackingAxeptioCookiesVersionPrefix}-${newLocale}`
    );
  });

  watch(choices, (newChoices, oldChoices) => {
    if (!isInit.value || !isTrackingAvailable.value || !process.client) return;

    // Reload window if user has disabled tracking for a vendor
    if (
      keysOf(choices.value).some(key => oldChoices[key] && !newChoices[key])
    ) {
      window.location.reload();
      return;
    }
  });

  return { choices, hasMadeChoice };
}
