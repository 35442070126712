import { MonextErrorCode, CwAppErrorCode } from '@/models/enums';
import { errorRegistry } from '@/config/errors';

import type { ErrorTranslationKeyPayloads } from '@/config/errors';

// Map Monext error codes to our own error codes to avoid using error codes from external sources (potential conflicts)
// https://docs.monext.fr/pages/viewpage.action?pageId=747146776
const monextErrorToAppErrorMapper: Record<MonextErrorCode, CwAppErrorCode> = {
  '02303': CwAppErrorCode.MonextInvalidMonextContractNumber,
  '02540': CwAppErrorCode.MonextNoCardFoundForThisToken,
  '02623': CwAppErrorCode.MonextMaximumAttemptsExceeded,
  '02624': CwAppErrorCode.MonextExpiredCard,
  '02625': CwAppErrorCode.MonextInvalidCardNumber,
  '02626': CwAppErrorCode.MonextInvalidExpirationDate,
  '02627': CwAppErrorCode.MonextInvalidCVC,
  '02703': CwAppErrorCode.MonextUnauthorizedAction,
  '02713': CwAppErrorCode.MonextInvalidToken,
  '09101': CwAppErrorCode.MonextUnauthorizedAccess,
};

export function monextErrorToAppError(
  monextErrorCode: MonextErrorCode
): CwAppErrorCode {
  return (
    monextErrorToAppErrorMapper[monextErrorCode] ?? CwAppErrorCode.InternalIssue
  );
}

export function getErrorTranslationKey(
  code: CwErrorCode,
  payloads: ErrorTranslationKeyPayloads,
  context?: ErrorContext
): string {
  const error = errorRegistry.find(
    error =>
      error.code === code &&
      ((!context && !error.context) || context === error.context)
  );

  return typeof error?.translationKey == 'function'
    ? error?.translationKey(payloads)
    : (error?.translationKey ?? 'error__default');
}

export class CwError extends Error {
  code: CwErrorCode;

  constructor(errorCode: CwErrorCode) {
    super();
    this.name = this.constructor.name;
    this.code = errorCode;
  }
}

/**
 * Wrapper for CwError object
 * @param errorCode - A CW error code
 * @returns a CwError object
 */
export function createCwError(errorCode: CwErrorCode): CwError {
  return new CwError(errorCode);
}
