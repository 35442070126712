import piwa from 'piwa';
import { stringify } from 'qs';
import { keysOf } from '@fifteen/shared-lib';

import type { NitroFetchRequest, TypedInternalResponse } from 'nitropack';

type UseStrapiOptions<T> = {
  /**
   * Array of locales to restrict for the request. If the current user locale is not in the subset, take the Strapi default locale
   */
  localesSubset?: StrapiLocaleCode[];
  /**
   * Strapi API query params (for filtering, sorting...)
   */
  queryParams?: PickStrapiRequestParams<T>;
  /**
   * Save the returned data in a Redis cache to be use later as fallback, in case of CMS unavailability. Available on SSR only.
   */
  offlineSSRFallback?: boolean;
  /**
   * Fetch again the data when locale changes
   */
  watchLocale?: boolean;
};

type PickStrapiRequestParams<T> = T extends keyof StrapiRequestParamsMap
  ? StrapiRequestParamsMap[T]
  : StrapiRequestParams;

type StrapiRequestParamsMap = {
  'blog-posts': BlogPostStrapiRequestParams;
  navigation: NavigationStrapiRequestParams;
  'showcase-footer': ShowcaseFooterStrapiRequestParams;
};

interface StrapiContentTypeMap {
  showcase: ShowcaseContentType;
  about: AboutContentType;
  'account-settings': AccountSettingsContentType;
  'communication-preferences': CommunicationPreferencesContentType;
  blog: BlogContentType;
  'blog-posts': BlogPostContentType[];
  profile: ProfileContentType;
  settings: SettingsContentType;
  'internal-settings': InternalSettingsContentType;
  'internal-profile': InternalProfileContentType;
  'internal-showcase': InternalShowcaseContentType;
  'legal-notice': LegalNoticeContentType;
  'privacy-policy': PrivacyPolicyContentType;
  'terms-and-condition': TermsAndConditionsContentType;
  'showcase-home': ShowcaseHomeContentType;
  'showcase-sharing': ShowcaseSharingContentType;
  'showcase-leasing': ShowcaseLeasingContentType;
  'showcase-help': ShowcaseHelpContentType;
  'showcase-map': ShowcaseMapContentType;
  'page-status': PageStatusContentType;
  'locale-status': LocaleStatusContentType;
  'referral-sharing': ReferralContentType;
  'referral-leasing': ReferralContentType;
  'dynamic-pages': DynamicPagesContentType[];
  'showcase-footer': ShowcaseFooterContentType;
}

interface StrapiPluginMap {
  navigation: NavigationItems;
  redirects: RedirectsPluginResponse;
}

// Routes for which Strapi does not wrap the response in a object with `attributes`, `data` and `meta` fields

type StrapiApiRoute = keyof StrapiContentTypeMap | keyof StrapiPluginMap;

type StrapiRawDataType<T, U> = T extends keyof StrapiContentTypeMap
  ? U extends string | number
    ? StrapiResponse<StrapiContentTypeMap[T]>['data'] extends (infer V)[]
      ? { data: V; meta: Record<string, unknown> }
      : StrapiResponse<StrapiContentTypeMap[T]>
    : StrapiResponse<StrapiContentTypeMap[T]>
  : T extends keyof StrapiPluginMap
    ? StrapiPluginMap[T]
    : never;

type UseStrapiFetchResponse<T, U> = TypedInternalResponse<
  NitroFetchRequest,
  StrapiRawDataType<T, U>
>;

type UseAsyncDataReturn<Data, Error> = ReturnType<
  typeof useAsyncData<Data, Error>
>;

function getApiRoute(apiRoute: StrapiApiRoute): string {
  const endpointMap: Partial<Record<StrapiApiRoute, string>> = {
    navigation: 'navigation/render',
  };

  if (keysOf(endpointMap).includes(apiRoute)) {
    return endpointMap[apiRoute] as string;
  }
  return apiRoute;
}

/**
 * Fetch data from Strapi REST API
 * @param apiRoute - Strapi API route, which targets a given content type
 * @param options.localesSubset - Array of locales to restrict for the request. If the current user locale is not in the subset, take the Strapi default locale
 * @param options.queryParams - Strapi API query params (for filtering, sorting...)
 * @param slugOrId - Slug or ID
 * @returns Promise with raw Strapi response
 */
export async function useStrapi<T extends StrapiApiRoute, U>(
  apiRoute: T,
  options?: UseStrapiOptions<T>,
  slugOrId?: U
): Promise<UseAsyncDataReturn<StrapiRawDataType<T, U>, Error | null>> {
  const finalApiRoute = getApiRoute(apiRoute);

  const config = useRuntimeConfig();

  const { isPreview } = useGlobalStore();
  const route = useRoute();
  const strapiLocale = useStrapiLocale(options?.localesSubset);

  return useAsyncData(
    // Key is built from apiRoute + queryParams to prevent unwanted requests deduplication
    finalApiRoute +
      (options?.queryParams ? JSON.stringify(options?.queryParams) : ''),
    async () => {
      const url = computed(() => {
        const queryParams = stringify(
          {
            ...(strapiLocale.value && {
              locale: isPreview ? route.params.locale : strapiLocale.value,
            }),
            ...options?.queryParams,
            ...(isPreview && {
              publicationState: 'preview',
            }),
          },
          {
            // Prettify the output by not encoding the keys
            encodeValuesOnly: true,
          }
        );
        const pathParams = slugOrId ? `/${slugOrId}` : '';

        return `${finalApiRoute}${pathParams}?${queryParams}`;
      });

      const { data, error } = await piwa<StrapiRawDataType<T, U>>(
        $fetch<StrapiRawDataType<T, U>>(url.value, {
          baseURL: config.public.strapiBaseUrl,
        }) as Promise<StrapiRawDataType<T, U>>
      );

      if (process.server && options?.offlineSSRFallback) {
        // Dynamically import module to avoid importing underlying
        // node redis in front-side from universal use
        const { useRedis } = await import('@/server/core/redis');
        const redis = useRedis({
          prefix: `${config.redisPrefix}:strapi:`,
        });
        // If the fetch to Strapi failed, we try to get the redis cache
        if (error) {
          const redisData =
            await redis.get<UseStrapiFetchResponse<T, U>>(finalApiRoute);
          if (!redisData) throw error;
          return redisData;
        }
        // Otherwise we set the redis cache for future fallback
        redis.set<StrapiRawDataType<T, U>>(finalApiRoute, data);
      }

      if (error) throw error;
      return data;
    },
    {
      watch: options?.watchLocale ? [strapiLocale] : [],
    }
  );
}
