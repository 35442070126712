import * as Sentry from '@sentry/vue';
import { defineStore } from 'pinia';

import { defaultLocale, supportedLocalesConfig } from '@/config/locales';
import { defaultTheme } from '@/config';
import { ServiceType, OTPMode } from '@/models/enums';

import type { SeoMetaOptions } from '@/composables/useClientSeoMeta';
import type { ClientGetConfigResponse } from '@/server/api/client/config.get';
import type { Theme } from '@/config';
import type { RouteLocationResolvedGeneric } from 'vue-router';
import type { ClientGetScopeConfigResponse } from '@/server/api/client/scope-config.get';

export interface ClientSocialNetwork {
  name?: SocialNetworkName;
  url?: string;
}

export interface HelpCenterLink {
  key: 'bank-authorization';
  link: string;
}

export interface MobileAppCarouselItem {
  text: string;
  image: { src: string; alt: string };
}

export interface ClientMobileAppOverview {
  device_mockup: { src: string; alt: string };
  carousel_items: MobileAppCarouselItem[];
  features: string[];
}

export interface MapConfig {
  center?: Coordinates;
  initialFitBounds: boolean;
  fitBoundsPadding: number;
  minZoom: number;
  maxZoom: number;
  initialZoom: number;
}

export interface DynamicLinkConfig {
  baseUrl: string;
  deepLinkBaseUrl: string;
  androidAppId: string;
  iosStoreId: string;
  iosAppId: string;
}

export interface InternalSettingsIdentityField {
  name:
    | 'sex'
    | 'firstname'
    | 'lastname'
    | 'birthdate'
    | 'email'
    | 'phone'
    | 'address';
  required?: boolean;
}

export interface InternalSettingsOptInField {
  name:
    | 'opt_in_invoice_subscription'
    | 'opt_in_newsletter'
    | 'opt_in_geolocation';
}

export interface ClientSettings {
  name: string;
  preferredServiceType: ServiceType;
  logoSrc: string;
  logoLightSrc: string;
  metropolisLogoSrc: string;
  metropolisLogoLightSrc: string;
  faviconSrc: string;
  sharingAppDeviceMockupSrc: string;
  leasingAppDeviceMockupSrc: string;
  phoneNumber: string;
  email: string;
  address: string;
  openingHours: string;
  socialNetworks: ClientSocialNetwork[];
  defaultSeo: SeoMetaOptions;
  helpCenterLinks: HelpCenterLink[];
  mobileAppsOverview: {
    sharing?: ClientMobileAppOverview;
    leasing?: ClientMobileAppOverview;
  };
}

export interface InternalSettings {
  homepage: string;
  sharingDynamicLink: string;
  leasingDynamicLink: string;
  sharingAppStoreLink: string;
  sharingPlayStoreLink: string;
  leasingAppStoreLink: string;
  leasingPlayStoreLink: string;
  modules: {
    accountSettings?: boolean;
    communicationPreferences?: boolean;
    embedMap?: boolean;
    profile?: boolean;
    showcase?: boolean;
  };
  services: {
    leasing: boolean;
    sharing: boolean;
    customerService: boolean;
  };
  features: {
    referral: boolean;
  };
  theme: Theme;
  stationsMapConfig?: MapConfig;
  dynamicLinksConfigs?: {
    sharing?: DynamicLinkConfig;
    leasing?: DynamicLinkConfig;
  };
  otpMode: OTPMode;
  identityFields: InternalSettingsIdentityField[];
  optInFields: InternalSettingsOptInField[];
  countryCode: CountryCode;
}

export interface PageStatus {
  pages: {
    showcaseAbout: boolean;
    showcaseBlog: boolean;
    showcaseHome: boolean;
    showcaseSharing: boolean;
    showcaseLeasing: boolean;
    showcaseHelp: boolean;
    showcaseMap: boolean;
    referralSharing: boolean;
    referralLeasing: boolean;
  };
}

export interface LocaleSettings {
  defaultLocale: ISOLocale;
  locales: ISOLocale[];
}

export interface AreaId {
  areaId: string;
}

export type ClientState = ClientSettings &
  Omit<InternalSettings, 'homepage'> &
  LocaleSettings &
  PageStatus &
  ClientGetConfigResponse['clientConfig'] &
  ClientGetScopeConfigResponse &
  AreaId;

export const useClientStore = defineStore('client', () => {
  const router = useRouter();
  const api = useApi();
  const { resolveLocalizedRoute } = useLocalizedRoute();

  const { clientConfig } = useRuntimeConfig();

  const areaId = useState<string>(
    'useClientStore.areaId',
    () => clientConfig.areaIds[0]
  );

  const isLoading = ref(false);
  const clientState = ref<ClientState>({
    name: '',
    preferredServiceType: ServiceType.Sharing,
    logoSrc: '',
    logoLightSrc: '',
    metropolisLogoSrc: '',
    metropolisLogoLightSrc: '',
    faviconSrc: '',
    sharingAppDeviceMockupSrc: '',
    leasingAppDeviceMockupSrc: '',
    phoneNumber: '',
    email: '',
    address: '',
    openingHours: '',
    socialNetworks: [],
    stationsMapConfig: undefined,
    sharingAppStoreLink: '',
    sharingPlayStoreLink: '',
    leasingAppStoreLink: '',
    leasingPlayStoreLink: '',
    sharingDynamicLink: '',
    leasingDynamicLink: '',
    modules: {
      profile: false,
      showcase: false,
      embedMap: false,
    },
    pages: {
      showcaseAbout: false,
      showcaseBlog: false,
      showcaseHome: false,
      showcaseSharing: false,
      showcaseLeasing: false,
      showcaseHelp: false,
      showcaseMap: false,
      referralSharing: false,
      referralLeasing: false,
    },
    services: {
      leasing: false,
      sharing: false,
      customerService: false,
    },
    features: {
      referral: false,
    },
    defaultSeo: {
      title: '',
      description: '',
      imageUrl: '',
    },
    theme: defaultTheme,
    locales: [],
    defaultLocale,
    paymentProvider: {
      name: null,
      minimumAuthorizationAmount: 0,
      endpoint: '',
      allowedPaymentMethodTypes: {
        paypal: false,
      },
    },
    scopeConfig: {
      maxRfidPassPerUser: 0,
      minimumAge: 0,
      otpCodeLength: 4,
    },
    areaId: areaId.value,
    helpCenterLinks: [],
    mobileAppsOverview: {
      sharing: undefined,
      leasing: undefined,
    },
    dynamicLinksConfigs: {
      sharing: {
        baseUrl: '',
        deepLinkBaseUrl: '',
        androidAppId: '',
        iosStoreId: '',
        iosAppId: '',
      },
      leasing: {
        baseUrl: '',
        deepLinkBaseUrl: '',
        androidAppId: '',
        iosStoreId: '',
        iosAppId: '',
      },
    },
    otpMode: OTPMode.Phone,
    identityFields: [],
    optInFields: [],
    countryCode: 'FR',
  });
  const homepage = ref<RouteLocationResolvedGeneric>(router.resolve('/'));

  async function initLocales(): Promise<void> {
    const { isPreview } = useGlobalStore();
    const { data: localeStatus } = await useCmsLocaleStatus();
    const { defaultLocale, locales } = localeStatus.value;

    clientState.value = {
      ...clientState.value,
      defaultLocale,
      locales: isPreview
        ? supportedLocalesConfig.map(({ locale }) => locale)
        : locales,
    };
  }

  async function init(): Promise<void> {
    const [
      { data: settingsData, pending: pendingSettings },
      { data: internalSettingsData, pending: pendingInternalSettings },
      { data: pageStatus, pending: pendingPageStatus },
      { data: clientConfigData, loading: pendingClientConfigData },
      { data: clientScopeConfigData, loading: pendingClientScopeConfigData },
    ] = await Promise.all([
      useCmsSettings(),
      useCmsInternalSettings(),
      useCmsPageStatus(),
      api.get('/client/config'),
      api.get('/client/scope-config'),
    ]);

    watch(
      [
        pendingSettings,
        pendingInternalSettings,
        pendingPageStatus,
        pendingClientConfigData,
        pendingClientScopeConfigData,
      ],
      () => {
        isLoading.value =
          pendingSettings.value ||
          pendingInternalSettings.value ||
          pendingPageStatus.value ||
          pendingClientConfigData.value ||
          pendingClientScopeConfigData.value;
      }
    );

    watchImmediate(
      [
        settingsData,
        internalSettingsData,
        pageStatus,
        clientConfigData,
        clientScopeConfigData,
      ],
      () => {
        clientState.value = {
          ...clientState.value,
          ...settingsData.value,
          ...internalSettingsData.value,
          ...pageStatus.value,
          ...clientConfigData.value?.clientConfig,
          ...clientScopeConfigData.value,
        };

        const homepageRoute = resolveLocalizedRoute(
          internalSettingsData.value.homepage
        );

        // If the resolvedRoute if a string, it means that the route is an external one (not handled by Vue router)
        // In this case, we resolve it to the default homepage ('/')
        homepage.value = {
          ...(typeof homepageRoute === 'string'
            ? router.resolve('/')
            : homepageRoute),
          matched: [],
        } as RouteLocationResolvedGeneric;
      }
    );
  }

  // Attach context to Sentry, to know the involved client
  Sentry.setContext('client', {
    name: clientState.value.name,
    modules: clientState.value.modules,
    homepage: homepage.value.name,
  });

  return {
    init,
    initLocales,
    clientState,
    homepage,
    isLoading,
    isEmailOtpMode: computed(() => clientState.value.otpMode === OTPMode.Email),
  };
});
