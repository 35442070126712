import { mapCmsImageToImageProps } from '@/lib/cms-mappers';

import type {
  FcwFooterProps,
  ShowcaseFooterColumnItem,
} from '@/components/organisms/FcwFooter.vue';

export function mapCmsFooterDataToFooterProps(
  footerRawData: StrapiResponseData<ShowcaseFooterContentType> | undefined
): FcwFooterProps {
  const cmsData = footerRawData?.attributes;

  return {
    leftLogo: mapCmsImageToImageProps(cmsData?.logo, 'medium'),
    columns: [
      (cmsData?.first_section ?? []).map(mapCmsFooterSectionItemsToProps),
      (cmsData?.second_section ?? []).map(mapCmsFooterSectionItemsToProps),
      (cmsData?.third_section ?? []).map(mapCmsFooterSectionItemsToProps),
    ],
  };
}

function mapCmsFooterSectionItemsToProps(
  sectionItem: ShowcaseFooterSectionItemContent
): ShowcaseFooterColumnItem {
  switch (sectionItem.__component) {
    case 'showcase-footer.social-networks':
      return mapSocialNetworksToProps(sectionItem);
    case 'showcase-footer.footer-links':
      return mapFooterLinksToProps(sectionItem);
    case 'showcase-footer.general-contact':
      return mapGeneralContactToProps(sectionItem);
    case 'showcase-footer.contact':
      return mapContactToProps(sectionItem);
    case 'showcase-footer.latest-news':
      return mapLatestNewsToProps(sectionItem);
    case 'showcase-footer.news':
      return mapNewsToProps(sectionItem);
    case 'showcase-footer.cta-button':
      return mapCtaButtonToProps(sectionItem);
  }
}

function mapSocialNetworksToProps(
  footerSocialNetworks: ShowcaseFooterSocialNetworks
): ShowcaseFooterColumnItem {
  return {
    type: 'showcase-footer.social-networks',
    props: {
      title: footerSocialNetworks.title ?? '',
    },
  };
}

function mapFooterLinksToProps(
  footerLinks: ShowcaseFooterLinks
): ShowcaseFooterColumnItem {
  return {
    type: 'showcase-footer.footer-links',
    props: {
      title: footerLinks.title ?? '',
      items: (footerLinks.links ?? []).map(link => {
        const dynamicPage = link?.dynamic_page?.data?.attributes;
        const blogPost = link?.blog_post?.data?.attributes;

        return {
          label: link?.text ?? blogPost?.title ?? '',
          location: link?.url ?? '',
          icon: link?.icon,
          type: link?.type ?? 'internal-link',
          internalPage: link?.page ?? undefined,
          dynamicPageSlug: dynamicPage?.slug ?? '',
          blogPostSlug: blogPost?.slug ?? '',
        };
      }),
    },
  };
}

function mapGeneralContactToProps(
  footerGeneralContact: ShowcaseFooterGeneralContact
): ShowcaseFooterColumnItem {
  return {
    type: 'showcase-footer.general-contact',
    props: {
      title: footerGeneralContact.title ?? '',
    },
  };
}

function mapContactToProps(
  footerContact: ShowcaseFooterContact
): ShowcaseFooterColumnItem {
  return {
    type: 'showcase-footer.contact',
    props: {
      title: footerContact.title ?? '',
      email: footerContact?.email ?? '',
      openingHours: footerContact?.opening_hours ?? '',
      phoneNumber: footerContact?.phone_number ?? '',
    },
  };
}

function mapLatestNewsToProps(
  footerLatestNews: ShowcaseFooterLatestNews
): ShowcaseFooterColumnItem {
  return {
    type: 'showcase-footer.latest-news',
    props: {
      title: footerLatestNews.title ?? '',
      items: (footerLatestNews?.blog_posts?.data ?? []).map(blogPost => ({
        slug: blogPost.attributes.slug,
        label: blogPost.attributes.title,
      })),
    },
  };
}

function mapNewsToProps(
  footerNews: ShowcaseFooterNews
): ShowcaseFooterColumnItem {
  return {
    type: 'showcase-footer.news',
    props: {
      title: footerNews.title ?? '',
      items: (footerNews?.blog_posts?.data ?? []).map(blogPost => ({
        slug: blogPost.attributes.slug,
        label: blogPost.attributes.title,
      })),
    },
  };
}

function mapCtaButtonToProps(
  footerCtaButton: ShowcaseFooterCtaButton
): ShowcaseFooterColumnItem {
  return {
    type: 'showcase-footer.cta-button',
    props: {
      text: footerCtaButton.text ?? '',
      location: footerCtaButton.url ?? '',
    },
  };
}
