import { createFds } from '@fifteen/design-system-vue';
import * as icons from '@fifteen/design-system-vue/icons';
import * as creditCardIcons from '@fifteen/design-system-vue/icons/credit-cards';

/**
 * Plugin that init fds
 */
export default defineNuxtPlugin({
  name: 'fds',
  async setup() {
    const { vueApp } = useNuxtApp();
    const fds = createFds({
      icons,
      creditCardIcons,
    });
    vueApp.use(fds);
    vueApp.provide('creditCardTypes', Object.keys(creditCardIcons));
  },
});
