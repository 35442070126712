<template lang="pug">
footer.FcwFooter
  .FcwFooter__content(v-if="leftLogo && columns?.length")
    .FcwFooter__logoSection(v-if="leftLogo?.src")
      FImage(
        v-bind="leftLogo"
        width="124"
        height="inherit"
        loading="eager"
        corners="sharp"
      )
    .FcwFooter__dynamicZones
      FcwFooterColumn(
        v-for="(column, index) in columns"
        :key="index"
        :items="column"
      )

  .FcwFooter__localeSelectAndLinks
    .FcwFooter__localeSelect
      FcwLocaleSelect(display-mode="name")
    .FcwFooter__bottomLinks
      template(
        v-for="(link, index) in bottomLinks"
        :key="index"
      )
        FcwRouterLink(
          color="neutral--light-5"
          :location="link.location"
          hide-arrow
        ) {{ link.label }}
        span(v-if="isNotLastLink(index)") -
      span |
      span {{ t('footer__powered_by') }}
      FcwRouterLink.FcwFooter__fifteen(
        color="neutral--light-5"
        location="https://fifteen.eu"
        target="_blank"
        hide-arrow
      ) Fifteen
</template>

<style lang="stylus">
.FcwFooter
  position relative
  padding rem(56) rem(80)
  background-color var(--color--neutral--dark-5)
  color var(--color--neutral--light-5)
  border-top-right-radius rem(24)
  border-top-left-radius rem(24)
  display flex
  flex-direction column
  gap rem(48)

  &__logoSection
    +media-down('sm')
      margin-bottom rem(24)

  &__content
    display flex

    +media-down('sm')
      flex-direction column
      align-items center

  &__dynamicZones
    display flex
    gap rem(40)
    margin-left rem(80)

    +media-down('md')
      flex-wrap wrap

    +media-down('sm')
      align-items center
      flex-direction column
      margin-left 0

  &__localeSelectAndLinks
    display flex
    justify-content flex-start
    align-items center

    +media-down('sm')
      flex-direction column
      gap rem(8)

  &__bottomLinks
    position absolute
    left 50%
    transform translateX(-50%)
    display flex
    gap rem(8)
    white-space nowrap

    +media-down('sm')
      position static
      transform translateX(0)

  &__fifteen
    margin-left rem(-4)

  &__localeSelect
    width min-content
</style>

<script setup lang="ts">
import FcwFooterColumn from './showcase/footer/FcwFooterColumn.vue';

import type { FImageProps } from '@fifteen/design-system-vue';
import type { FcwFooterLinksProps } from '@/components/organisms/showcase/footer/FcwFooterLinks.vue';
import type { FcwSocialNetworksProps } from '@/components/organisms/showcase/footer/FcwFooterSocialNetworks.vue';
import type { FcwContactProps } from '@/components/organisms/showcase/footer/FcwFooterContact.vue';
import type { FcwFooterNewsProps } from '@/components/organisms/showcase/footer/FcwFooterNews.vue';
import type { FcwButtonLinkProps } from '@/components/molecules/FcwButtonLink.vue';

export type ShowcaseFooterColumnItemMap = {
  'showcase-footer.footer-links': FcwFooterLinksProps;
  'showcase-footer.social-networks': FcwSocialNetworksProps;
  'showcase-footer.latest-news': FcwFooterNewsProps;
  'showcase-footer.general-contact': FcwContactProps;
  'showcase-footer.contact': FcwContactProps;
  'showcase-footer.news': FcwFooterNewsProps;
  'showcase-footer.cta-button': FcwButtonLinkProps & { text?: string };
};

export type ShowcaseFooterColumnItem = {
  [U in keyof ShowcaseFooterColumnItemMap]: {
    type: U;
    props: ShowcaseFooterColumnItemMap[U];
  };
} extends infer T
  ? T[keyof T]
  : never;

export interface FcwFooterProps {
  /**
   * Whether the footer is in its minimal version
   */
  minimal?: boolean;
  /**
   * Footer's left logo
   */
  leftLogo?: FImageProps;
  /**
   * Footer's columns
   */
  columns?: ShowcaseFooterColumnItem[][];
}

const props = withDefaults(defineProps<FcwFooterProps>(), {
  minimal: false,
  leftLogo: undefined,
  columns: () => [],
});

const { t } = useI18n();

function isNotLastLink(index: number): boolean {
  return index < bottomLinks.value.length - 1;
}

const bottomLinks = computed(() => {
  const links = [
    {
      label: t('footer__privacy_policy'),
      location: { name: 'privacy-policy' },
    },
    {
      label: t('footer__terms_and_conditions'),
      location: { name: 'terms-and-conditions' },
    },
    {
      label: t('footer__legal_notice'),
      location: { name: 'legal-notice' },
    },
  ];

  return props.minimal
    ? links
    : [
        {
          label: t('footer__legal_notice'),
          location: { name: 'legal-notice' },
        },
      ];
});
</script>
