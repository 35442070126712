import { mapCmsFooterDataToFooterProps } from '@/lib/cms-mappers';

import type { FcwFooterProps } from '@/components/organisms/FcwFooter.vue';

/**
 * Returns about data fetched from CMS (Strapi)
 */
export async function useCmsFooter(): UseCmsResponse<FcwFooterProps> {
  const { data, error, pending, refresh } = await useStrapi('showcase-footer', {
    queryParams: {
      populate: [
        'logo',

        'first_section.links',
        'first_section.links.dynamic_page',
        'first_section.links.blog_post',
        'first_section.blog_posts',
        'first_section.social_networks',

        'second_section.links',
        'second_section.links.dynamic_page',
        'second_section.links.blog_post',
        'second_section.blog_posts',
        'second_section.social_networks',

        'third_section.links',
        'third_section.links.dynamic_page',
        'third_section.links.blog_post',
        'third_section.blog_posts',
        'third_section.social_networks',
      ],
      latest_news_fields: ['title', 'slug'],
    },
    watchLocale: true,
  });

  const mappedData = computed(() =>
    mapCmsFooterDataToFooterProps(data.value?.data)
  );

  return { data: mappedData, error, pending, refresh };
}
