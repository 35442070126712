<template lang="pug">
.FcwFooterContact
  h5.FcwFooterContact__title {{ title }}
  FcwRouterLink.FcwFooterContact__item(
    v-if="phoneNumber"
    :location="`tel:${phoneNumber}`"
    color="neutral--light-5"
    hide-arrow
    @click="trackOpenAContactLink('tel')"
  )
    FIcon.FcwFooterContact__itemIcon(
      name="supportHelp"
      color="primary--light-1"
    )
    span {{ phoneNumber }}

  FcwRouterLink.FcwFooterContact__item(
    v-if="email"
    :location="`mailto:${email}`"
    color="neutral--light-5"
    hide-arrow
    @click="trackOpenAContactLink('mailto')"
  )
    FIcon.FcwFooterContact__itemIcon(
      name="mail"
      color="primary--light-1"
    )
    span {{ email }}

  .FcwFooterContact__item
    FIcon.FcwFooterContact__itemIcon(
      v-if="openingHours"
      name="clock"
      color="primary--light-1"
    )
    span {{ openingHours }}
</template>

<style lang="stylus">
.FcwFooterContact
  display flex
  flex-direction column
  align-items flex-start
  gap rem(8)

  &__title
    color var(--color--primary--light-1)
    font-weight 400
    margin-bottom rem(16)

  &__item
    display flex

  &__itemIcon
    margin-right rem(8)
</style>

<script setup lang="ts">
export interface FcwContactProps {
  /**
   * Title of the contact block
   */
  title?: string;
  /**
   * Opening hours of the support team
   */
  openingHours?: string;
  /**
   * Phone number of the support team
   */
  phoneNumber?: string;
  /**
   * Contact's email
   */
  email?: string;
}

withDefaults(defineProps<FcwContactProps>(), {
  title: '',
  openingHours: '',
  phoneNumber: '',
  email: '',
});

const { track } = useUserTracking();

function trackOpenAContactLink(type: 'tel' | 'mailto'): void {
  switch (type) {
    case 'tel':
      track.openAContactLink({
        contactMode: 'phone',
      });
      break;
    case 'mailto':
      track.openAContactLink({
        contactMode: 'email',
      });
      break;
  }
}
</script>
