<template lang="pug">
FLocaleSelect.FcwLocaleSelect(
  v-if="clientState.locales?.length > 1"
  :model-value="locale"
  :locale-codes="clientState.locales"
  color="transparent"
  text-color="secondary--light-1"
  focus-color="transparent"
  border-color="secondary--light-1"
  focus-border-color="secondary--light-1"
  outline-color="secondary"
  options-menu-color="neutral--light-5"
  option-text-color="secondary"
  selected-option-color="neutral--light-3"
  selected-option-text-color="secondary"
  menu-width="112"
  :size="size"
  hide-hint
  :option-label-format="formatLabelOption"
  @update:model-value="onLocaleChange"
)
</template>

<style lang="stylus">
.FcwLocaleSelect .FMenu__option__content
  text-wrap nowrap
</style>

<script setup lang="ts">
import type { FSelectSize } from '@fifteen/design-system-vue';

export interface FcwLocaleSelectProps {
  /**
   * Size of the select
   */
  size?: FSelectSize;
}

withDefaults(defineProps<FcwLocaleSelectProps>(), {
  size: 'medium',
});

const { t } = useClientI18n();
const { locale, setLocale } = useLocale();
const { clientState } = useClientStore();
const { track } = useUserTracking();

function onLocaleChange(newLocale: string | null): void {
  if (!newLocale) return;

  setLocale(newLocale as StrapiLocaleCode);
  track.switchLocale({ uiLocale: newLocale });
}

function formatLabelOption(locale: string): string {
  return t(`common__lang__${locale ?? ''}`);
}
</script>
