import { enGB, es, fr, it, de, pt } from 'date-fns/locale';

import type { Locale } from 'date-fns';

export const supportedLocalesConfig: SupportedLocaleConfiguration[] = [
  {
    locale: 'ast',
    countryCode: 'ES',
    dateFns: es,
    fallback: 'es',
  },
  { locale: 'de', countryCode: 'DE', dateFns: de },
  { locale: 'en', countryCode: 'GB', dateFns: enGB },
  { locale: 'es', countryCode: 'ES', dateFns: es },
  { locale: 'fr', countryCode: 'FR', dateFns: fr },
  { locale: 'it', countryCode: 'IT', dateFns: it },
  { locale: 'pt', countryCode: 'PT', dateFns: pt },
];

// This default locale will likely not be used in production because of the multiple fallback
export const defaultLocale: ISOLocale = 'en';

interface SupportedLocaleConfiguration {
  /**
   * Locale of the configuration
   */
  locale: ISOLocale;
  /**
   * Country code to use for the locale
   */
  countryCode?: CountryCode;
  /**
   * date-fns lib locale configuration to use
   */
  dateFns: Locale;
  /**
   * Optional fallback for the locale, e.g. if it's not common (ISO-639-1).
   */
  fallback?: ISOLocale;
}
